import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Typed from "react-typed";
// eslint-disable-next-line import/no-named-as-default
import Particles from "react-tsparticles";
import Button from "@ui/button";
import Image from "@ui/image";
import { ImageType, HeadingType, ButtonType } from "@utils/types";

// eslint-disable-next-line react/prop-types
const HeroArea = ({ data, id, cursor }) => {
    const cursorRef = cursor;
    const cursorScaleRef = useRef(null);

    useEffect(() => {
        cursorScaleRef.current.addEventListener("mouseleave", () => {
            cursorRef.current.classList.remove("grow");
            cursorRef.current.classList.remove("grow-small");
            cursorRef.current.style.transform =
                "translate(-50%, -50%) scale(1)";
            cursorRef.current.style.border = "2px solid #00dbff";
        });
        cursorScaleRef.current.addEventListener("mousemove", () => {
            cursorRef.current.classList.add("grow");
            cursorRef.current.style.transform = "scale(5)";
            cursorRef.current.style.border = "none";
            if (cursorScaleRef.current.classList.contains("small")) {
                cursorRef.current.classList.remove("grow");
                cursorRef.current.classList.add("grow-small");
            }
        });
    }, [cursorRef]);
    return (
        <>
            <section
                id={id}
                className="slider-style-6 web-developer height--100 rn-section-gap align-items-center with-particles"
                data-black-overlay="5"
            >
                <Particles
                    className="particles"
                    canvasClassName="tsparticles-canvas-el"
                    params={{
                        fullScreen: { enable: false, zIndex: 0 },
                        particles: {
                            number: {
                                value: 160,
                                density: {
                                    enable: false,
                                },
                            },
                            size: {
                                value: 3,
                                random: true,
                                anim: {
                                    speed: 4,
                                    size_min: 0.3,
                                },
                            },
                            line_linked: {
                                enable: false,
                            },
                            move: {
                                random: true,
                                speed: 1,
                                direction: "top",
                                out_mode: "out",
                            },
                        },
                        interactivity: {
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: "bubble",
                                },
                                onclick: {
                                    enable: true,
                                    mode: "repulse",
                                },
                            },
                            modes: {
                                bubble: {
                                    distance: 250,
                                    duration: 2,
                                    size: 0,
                                    opacity: 0,
                                },
                                repulse: {
                                    distance: 400,
                                    duration: 4,
                                },
                            },
                        },
                    }}
                />
                {data?.images?.[0]?.src && (
                    <div className="hero-img w-100 h-100">
                        <Image
                            className="w-100 h-100"
                            src={data.images[0].src}
                            alt={data.images[0]?.alt || "Hero"}
                        />
                    </div>
                )}
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="banner-inner text-center">
                                {data?.headings?.[0] && (
                                    <h1
                                        className="fs--100"
                                        ref={cursorScaleRef}
                                        dangerouslySetInnerHTML={{
                                            __html: data.headings[0].content,
                                        }}
                                    />
                                )}
                                {data?.animated_texts && (
                                    <span
                                        className="header-caption"
                                        id="page-top"
                                    >
                                        <span className="cd-headline clip is-full-width">
                                            <span>I do</span>{" "}
                                            <Typed
                                                strings={data.animated_texts}
                                                typeSpeed={50}
                                                backSpeed={50}
                                                backDelay={1}
                                                loop
                                                smartBackspace
                                            />
                                        </span>
                                    </span>
                                )}
                                <div className="button-area">
                                    {data?.buttons?.map((btn) => (
                                        <Button
                                            key={btn.id}
                                            className={btn.className}
                                            path={btn.path}
                                        >
                                            <span>{btn.content}</span>
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

HeroArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        animated_texts: PropTypes.arrayOf(PropTypes.string),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

HeroArea.defaultProps = {
    id: "home",
};

export default HeroArea;
